import { SclaApplication } from 'features/scla/types';
import { makePutRequest } from 'lib/axios';

const submitSclaApp = async (
  id: string,
  email: string,
  name: string,
): Promise<SclaApplication> => {
  const url = `/application/submit/${id}/${email}/${name}`;
  const response = await makePutRequest(url, {});
  return response.data.payload;
};

export default submitSclaApp;
