import { useEffect, useState } from 'react';
import { Schema } from 'rsuite';
import serverErrorHandler from 'services/serverError.service';
import { getFormValidationStatus, scrollToFormErr } from 'utils/form.util';
import { FormField } from 'components/Form/CustomForm';
import { IUpdateSclaPrompts, SclaApplication } from 'features/scla/types';
import useAppNavigation from 'hooks/useAppNavigation';
import updateSealsAppPrompts from 'features/scla/api/updateSclaAppPrompts';
import { PICKER_SCLA_CHARACTER } from 'constants/picker';
import { openSuccessToaster } from 'services/toast.service';

// Extract schema types for form validation
const { ArrayType, StringType } = Schema.Types;

/**
 * Define validation model for login form.
 * User must provide a valid email and password
 * in order to submit the form.
 *
 * rsuite(5.5.2): https://rsuitejs.com/components/form-validation/
 */
const model = Schema.Model({
  wordSelection: ArrayType()
    .isRequired('Please select three options')
    .minLength(3, 'Please select three options.')
    .maxLength(3, 'Please select no more than three options.'),
  obstaclePrompt: StringType().isRequired('Please answer this question.'),
  curiosityPrompt: StringType().isRequired('Please answer this question.'),
});

const INIT_FORM: IUpdateSclaPrompts = {
  wordSelection: [],
  obstaclePrompt: 'string',
  curiosityPrompt: 'string',
};

const fields: FormField[] = [
  {
    name: 'wordSelection',
    type: 'multi_select',
    label:
      'Select three words from the list below that your best friend would use to describe you.',
    size: 'lg',
    data: {
      picker: PICKER_SCLA_CHARACTER,
      searchable: false,
    },
    required: true,
  },
  {
    name: 'obstaclePrompt',
    type: 'textarea',
    label: `Tell us about a time curiosity got you in trouble.\nWhat was your main takeaway from that experience?`,
    size: 'lg',
    data: {
      rows: 20,
    },
    required: true,
  },
  {
    name: 'curiosityPrompt',
    type: 'textarea',
    label: `Write about a time you overcame an obstacle.\nHow did you solve that problem?`,
    size: 'lg',
    data: {
      rows: 20,
    },
    required: true,
  },
];

export default function useUpdateSclaAppPrompts(
  app: SclaApplication,
  setApplication: (val: SclaApplication) => void,
) {
  const [formValue, setFormValue] =
    useState<IUpdateSclaPrompts>(INIT_FORM); // set default form values
  const [isLoading, setIsLoading] = useState(false); // flag for submission process
  const { navToSclaAppSignature } = useAppNavigation();

  const mapDefaultVales = (data: SclaApplication) => {
    const { promptInfo: prompts } = data;
    const map: IUpdateSclaPrompts = {
      wordSelection: prompts.wordSelection || [],
      obstaclePrompt: prompts.obstaclePrompt || '',
      curiosityPrompt: prompts.curiosityPrompt || '',
    };
    setFormValue(map);
  };

  useEffect(() => {
    mapDefaultVales(app);
  }, [app]);

  const onSubmit = async () => {
    try {
      // verify that form is valid
      if (getFormValidationStatus(model.check(formValue))) {
        // show spinner
        setIsLoading(true);
        const response = await updateSealsAppPrompts(app._id, {
          ...formValue,
        });
        setApplication({ ...app, promptInfo: response.promptInfo });
        openSuccessToaster('Saved prompts.', 3000);
        setIsLoading(false);
        navToSclaAppSignature();
      } else {
        scrollToFormErr();
      }
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  const onChange = (val: IUpdateSclaPrompts) => {
    setFormValue(val);
  };

  return {
    fields,
    formValue,
    isLoading,
    model,
    onChange,
    onSubmit,
  };
}
